import Axios from 'axios';
import { z } from 'zod';

import axios from 'src/utils/axios';

import {
  AddressValidatorValidatorSchema,
  AddressValidatorType,
} from '../validators/addressValidatorValidator';

interface Address {
  city: string;
  country_code: string;
  address_line_2: string | null | undefined;
  postal_code: string;
  region: string | null | undefined;
  address_line_1: string;
  phone_number?: string | null | undefined;
}

export const compareResult = (
  address: Address,
  result: AddressValidatorType,
): boolean => {
  const city = result.City || '';
  const addressLine2 = String(result.AddressLine2 || '');
  const postalCode = result.PostalCode || '';
  const region = result.State || address.region || '';
  const addressLine1 = result.AddressLine1 || '';

  return (
    address.city.toLowerCase() === city.toLowerCase() &&
    (address.address_line_2?.toLowerCase() ?? '') ===
      addressLine2.toLowerCase() &&
    address.postal_code.toLowerCase() === postalCode.toLowerCase() &&
    (address.region?.toLowerCase() ?? '') === region.toLowerCase() &&
    address.address_line_1.toLowerCase() === addressLine1.toLowerCase()
  );
};

export class InvalidAddressError extends Error {
  constructor(
    public readonly suggestions: Address[],
    public readonly type: 'suggestion' | 'error' = 'suggestion',
    message = 'Invalid address',
  ) {
    super(message);
    this.name = 'SuggestionsError';

    Object.setPrototypeOf(this, InvalidAddressError.prototype);
  }
}

export const validateAddress = async (address: Address) => {
  try {
    const { data } = await axios.post('/verify-address', address);
    const validationResult = z
      .array(AddressValidatorValidatorSchema)
      .safeParse(data.data);

    if (validationResult.success) {
      const results = validationResult.data;

      if (results.length === 0) {
        return;
      }

      if (results.some(result => compareResult(address, result))) {
        return;
      }

      const suggestions: Array<Address> = results.map(result => ({
        city: result.City || '',
        address_line_2: String(result.AddressLine2 ?? ''),
        postal_code: result.PostalCode || '',
        region: result.State || null,
        address_line_1: result.AddressLine1 || '',
        country_code: address.country_code,
        phone_number: address.phone_number,
      }));

      throw new InvalidAddressError(suggestions);
    }
  } catch (error) {
    if (Axios.isAxiosError(error)) {
      const data = error.response?.data as
        | {
            errors?: Array<{ errors?: Array<string> }>;
          }
        | undefined;
      const responseError = data?.errors?.[0]?.errors?.[0];

      if (typeof responseError === 'string') {
        throw new InvalidAddressError([], 'error', responseError);
      }
    }
    throw error;
  }

  throw new Error('Backend validation failed');
};
