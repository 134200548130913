import { FC, FocusEventHandler } from 'react';

import {
  Autocomplete,
  ListItem,
  TextFieldProps,
  Grid,
  FormHelperText,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import PhoneInput from 'src/components-for-storybook/atoms/PhoneInput';
import { TextField } from 'src/components-for-storybook/atoms/TextField/TextField';
import { Province } from 'src/utils/validators/province-validator';

import { useStyles } from './AddressForm.styles';

interface Country {
  name: string;
  code: string;
  id: number;
  requires_tin: boolean;
}

interface Value<T = string> {
  value?: T;
  error?: string | false;
  onChange?: (value: T) => void;
  onBlur?: T extends string
    ? FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
    : FocusEventHandler<HTMLDivElement>;
}

interface IBaseValues {
  postalCode: Value;
  addressLine2: Value;
  addressLine1: Value;
  city: Value;
}

type NormalValues = {
  type: 'primary';
  additionalAddress?: Value;
} & IBaseValues;

type OffboardValues = {
  type: 'offboard';
  personalPhone: Value;
  personalEmail: Value;
  additionalAddress: Value;
} & IBaseValues;

type PhoneValues = {
  type: 'phone';
  additionalAddress?: Value;
  phone: Value;
} & IBaseValues;

type Values = NormalValues | OffboardValues | PhoneValues;

export type AddressFormProps = {
  countrySelectionDisabled?: boolean;
  countrySelectionHelperText?: string;
  provinces?: Array<Province>;
  province: Value<Province | string | null>;
  countries: Array<Country>;
  country: Value<Country | null>;
} & Values;

const AddressForm: FC<AddressFormProps> = ({
  countrySelectionDisabled = false,
  countrySelectionHelperText,
  provinces,
  province,
  countries,
  country,
  ...values
}) => {
  const classes = useStyles();
  const { t } = useTranslation(['cart', 'common']);

  const getInputProps = (field: Value): TextFieldProps => ({
    fullWidth: true,
    size: 'small',
    value: field.value,
    onChange: e => field.onChange?.(e.target.value),
    onBlur: field.onBlur,
    error: Boolean(field.error),
    helperText: field.error,
    variant: 'outlined',
  });

  const shouldRenderAdditionalAddress = !!values.additionalAddress;

  return (
    <Grid container spacing={3}>
      {values.type === 'offboard' && (
        <>
          <Grid item md={6} xs={12}>
            <TextField
              data-testid="personal-phone-textfield"
              {...getInputProps(values.personalPhone)}
              name="personalPhone"
              label={t('common:input_label.personal_phone_number')}
              id="personalPhone"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              data-testid="personal-email-textfield"
              {...getInputProps(values.personalEmail)}
              name="personalEmail"
              label={t('common:input_label.personal_email_address')}
              id="personalEmail"
            />
          </Grid>
        </>
      )}

      {values.type === 'phone' && (
        <>
          <Grid item md={6} xs={12}>
            <PhoneInput
              value={values.phone.value}
              onChange={(v, d, e, value) => values.phone.onChange?.(value)}
              onBlur={values.phone.onBlur}
              specialLabel="Phone Number"
              isValid={Boolean(values.phone.value)}
              inputProps={{ name: 'phone', required: true }}
            />
            {values.phone.error && (
              <FormHelperText error style={{ marginLeft: 14 }}>
                {values.phone.error}
              </FormHelperText>
            )}
          </Grid>
        </>
      )}

      <Grid item md={6} xs={12}>
        <Autocomplete
          data-testid="country-autocomplete"
          id="country"
          options={countries}
          value={country.value}
          getOptionLabel={option => option.name}
          disabled={countrySelectionDisabled}
          renderOption={(props, option) => (
            <ListItem {...props}>
              {option.name} ({option.code})
            </ListItem>
          )}
          loadingText={`loading countries ...`}
          onBlur={country.onBlur}
          onChange={(e, value) =>
            typeof country.onChange === 'function'
              ? country.onChange(value)
              : undefined
          }
          classes={{ option: classes.option }}
          renderInput={params => (
            <TextField
              {...params}
              label={t('common:input_label.country')}
              variant="outlined"
              name="country"
              error={Boolean(country.error)}
              helperText={country.error ?? countrySelectionHelperText}
              size="small"
              fullWidth
            />
          )}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <TextField
          data-testid="postal-code-textfield"
          {...getInputProps(values.postalCode)}
          name="postalCode"
          label={t('common:input_label.postcode')}
          id="postcode"
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <TextField
          data-testid="address-line-1-textfield"
          {...getInputProps(values.addressLine1)}
          name="address_line_1"
          label={t('common:input_label.address_line_1')}
          id="address_line_1"
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <TextField
          data-testid="address-line-2-textfield"
          {...getInputProps(values.addressLine2)}
          name="address_line_2"
          label={t('common:input_label.address_line_2')}
          id="addressLine2"
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <TextField
          data-testid="city-textfield"
          {...getInputProps(values.city)}
          name="city"
          label={t('common:input_label.city')}
          id="city"
        />
      </Grid>
      <Grid item md={6} xs={12}>
        {country.value?.code === 'CA' ? (
          <Autocomplete
            value={typeof province.value === 'object' ? province.value : null}
            onChange={(e, value) => {
              province.onChange?.(value);
            }}
            options={provinces ?? []}
            renderInput={props => (
              <TextField
                {...props}
                {...getInputProps({
                  ...province,
                  value: province.value as string,
                  onBlur: province.onBlur as FocusEventHandler<
                    HTMLInputElement | HTMLTextAreaElement
                  >,
                })}
                size="small"
                label={t('common:input_label.region_state_province')}
                error={Boolean(province.error)}
              />
            )}
            getOptionLabel={option => option.name}
            renderOption={(props, option) => (
              <ListItem {...props}>{option.name}</ListItem>
            )}
          />
        ) : (
          <TextField
            data-testid="region-textfield"
            {...getInputProps({
              ...province,
              value: province.value as string,
              onBlur: province.onBlur as FocusEventHandler<
                HTMLInputElement | HTMLTextAreaElement
              >,
            })}
            name="region"
            label={t('common:input_label.region_state_province')}
            id="region"
          />
        )}
      </Grid>

      {shouldRenderAdditionalAddress && (
        <Grid item md={12} xs={12}>
          <TextField
            data-testid="additional-address-textfield"
            {...getInputProps(values.additionalAddress as Value<string>)}
            label={t('cart:additional_address_line')}
            id="additionalAddress"
          />
        </Grid>
      )}
    </Grid>
  );
};

export default AddressForm;
